import React, { useState } from 'react';
import { getFlowElement, NewButton, NewTabs, Input, Title, FILTER_TYPE, DataTable, SvgIcon, BrowserLocaleDateFormatter } from '../../';
import { $Grid, $TableButton } from './styles';
const tabs = [
    { id: 0, label: 'Documents' },
];
const columns = [
    { key: 'filename', noSort: true, type: FILTER_TYPE.NONE, name: 'Document Name',
        formatter(props) {
            const { filename = '' } = props.row;
            let nameArray = filename.split('_');
            if (nameArray.length)
                nameArray.shift();
            else
                nameArray = [''];
            return React.createElement("div", { "data-simplebar": true }, nameArray.join('_') || filename);
        } },
    { key: 'createdAt', noSort: true, type: FILTER_TYPE.NONE, name: 'Date', width: 90 },
    { key: 'download', noSort: true, type: FILTER_TYPE.NONE, name: '', width: 20,
        formatter(props) {
            const { download } = props.row;
            return download;
        } },
];
export const ApplicationDocuments = ({ isPRO, call, uid, documents = [], handleDownloadClick, downloadConsolidated }) => {
    const [tab, setTab] = useState(0);
    const onChangeTab = (newTab) => { setTab(newTab); };
    if (isPRO && tabs.length < 2)
        tabs.push({ id: 1, label: 'Notes' });
    const rows = documents.map((document) => {
        const aux = (document.filename || '').split('_');
        const element = getFlowElement(call, Number(document.flowElementId))?.element;
        return {
            filename: aux.length > 2 ? `${uid || ''}_${aux[1].trim()}_${element?.title || ''}` : document.filename,
            createdAt: BrowserLocaleDateFormatter.format(new Date(document.createdAt)),
            download: React.createElement($TableButton, null,
                React.createElement(SvgIcon, { name: 'download', clickable: true, size: 20, onClick: () => handleDownloadClick(document.id, document.flowElementId) })),
        };
    });
    const showTable = !!rows.length;
    return (React.createElement(React.Fragment, null,
        isPRO && React.createElement(NewTabs, { tabs: tabs, value: tab, onChange: onChangeTab }),
        React.createElement($Grid, { container: true }, tab == 0 ?
            React.createElement(React.Fragment, null,
                React.createElement(Title, { alternate: true, subTitle: true }, "Documents"),
                showTable && React.createElement(DataTable, { noFilters: true, rows: rows, columns: columns, showEmptyImg: false }),
                isPRO && showTable && React.createElement(NewButton, { disabled: true, type: 'submit' }, "Download All"),
                React.createElement(NewButton, { icon: React.createElement(SvgIcon, { name: 'download', size: '20', color: '#fff' }), onClick: downloadConsolidated }, "Consolidated Online Forms"))
            :
                React.createElement(React.Fragment, null,
                    React.createElement(Input, { debounce: true, title: 'Notes', name: 'notes', disabled: true, placeholder: 'Coming soon...', multiline: true, minRows: 8, type: 'text', hideItalic: true })))));
};
