import React from 'react';
export const useInfiniteScroll = (fetchData, hasMore) => {
    const loadMoreRef = React.useRef(null);
    const handleIntersection = React.useCallback((entries) => {
        const isIntersecting = entries[0]?.isIntersecting;
        if (isIntersecting && hasMore) {
            fetchData();
        }
    }, [fetchData, hasMore]);
    React.useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection);
        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }
        return () => observer.disconnect();
    }, [handleIntersection]);
    return { loadMoreRef };
};
