import React, { FormEvent } from 'react'
import { ADMIN_PORTAL_ROLES, ApiBaseError, Input, isEmail, NewButton, NotificationType, ROLES } from 'prace-common-components'
import { $Form, $Forgot, $Error } from './styles'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'constants/global'
import Grid from '@material-ui/core/Grid'
import { useAppDispatch } from 'store/hooks'
import { useLoginMutation } from 'store/api/auth'

export const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [login, { isLoading, error }] = useLoginMutation()
  const [formError, setFormError] = React.useState({ email: false, password: false })

  const handleSubmit = React.useCallback(async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      email: { value: string },
      password: { value: string },
    }

    const newFormError = {
      email: !(target.email.value && isEmail(target.email.value)),
      password: !target.password.value,
    }
    setFormError(newFormError)
    if(!(newFormError.email || newFormError.password)) {
      try {
        const res = await login({ email: target.email.value, password: target.password.value }).unwrap()
        const role = res?.user.role
        if(!ADMIN_PORTAL_ROLES.includes(role) && (role !== ROLES.SUPER_ADMIN)) {
          dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: 'Please use User Portal' } })
        }else {
          if(role === ROLES.SUPER_ADMIN) navigate(Routes.ADMINS)
          else navigate(Routes.HOME)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }, [dispatch, login, navigate])

  const errorMessage = error && (error as ApiBaseError).data !== 'Unauthorized' ? 
    (error as ApiBaseError).data?.message || '' : ''

  return (
    <$Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            title='E-mail'
            name='email'
            type='email'
            required
            error={formError.email}
            errorMsg={formError.email ? 'Invalid e-mail' : undefined}
            hideItalic
            withAutocomplete
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            title='Password'
            name='password'
            type='password'
            required
            error={formError.password}
            errorMsg={formError.password ? 'Invalid Password' : undefined}
            hideItalic
            withAutocomplete
          />
        </Grid>
        {errorMessage &&
          <Grid item xs={12}>
            <$Error>{errorMessage}</$Error>
          </Grid>
        }
        <Grid item xs={12}>
          <NewButton fullWidth type='submit' disabled={isLoading} loading={isLoading}>
            Log in
          </NewButton>
        </Grid>
        <Grid container item xs={12} justifyContent='center'>
          <$Forgot onClick={() => navigate(Routes.FORGOT_PASSWORD)}>
            Forgot password?
          </$Forgot>
        </Grid>
      </Grid>
    </$Form>
  )
}
