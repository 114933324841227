import styled from 'styled-components'
import { Grid, SvgIcon } from 'prace-common-components'
import { StepLabel, Stepper } from '@material-ui/core'

export const $Calls = styled.div`
  flex: 1;
  max-width: 1400px;
  padding: 50px 25px;
  padding-top: 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
  margin: 0 auto;
`

export const $SvgIcon = styled(SvgIcon)<{ primary?: boolean }>`
  color: ${({ theme, primary }) => primary ? theme.colors.primary : theme.colors.secondary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $CallContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

export const $CutoffContainer = styled(Grid)`
  max-width: 100%;
  overflow-x: auto;
  &.MuiGrid-container {
    margin: ${({ theme }) => theme.spacing(2)} 0;
  }
`

export const $CutoffButton = styled.button<{ selected?: boolean; clickable?: boolean }>`
  margin-right: ${({ theme }) => theme.spacing(1)};
  padding: 5px 10px;
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};
  min-height: 38px;
  box-shadow: none;
  color: ${({ theme, selected }) => selected ? theme.colors.secondary : '#c3c7c7'};
  border: 1px solid ${({ theme, selected }) => selected ? theme.colors.secondary : '#c3c7c7'};
  background-color: #fff;
  ${({ clickable }) => clickable && `
    &:hover {
      background-color: #fff;
      filter: brightness(95%);
    }
  `}
`

export const $StepIcon = styled.div<{ active?: boolean; completed?: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme, active, completed }) => active ? theme.colors.secondary : completed ? theme.colors.primary : theme.colors.grey};
  border: ${({ theme, active, completed }) => '1px solid ' +  (active ? theme.colors.secondary : completed ? theme.colors.primary : theme.colors.grey)};
  z-index: 1;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const $Stepper = styled(Stepper)`
  overflow-x: auto;
  &.MuiStepper-root {
    padding: 0px;
    padding-top: 12px;
  }
`

export const $StepContainer = styled.div`
  background-color: rgba(118, 140, 224, 0.15);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
`

export const $StepLabel = styled(StepLabel)`
  margin-top: 0;
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
    display: flex;
    justify-content: center;
  }
`

export const $CallUID = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)};
  font-size: 12px;
  font-weight: 700;
`

export const $CallSelect = styled.div`
  max-width: 600px;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

export const $Arrow = styled.div`
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(118, 140, 224, 0.15);
`

export const $Status = styled.span`
  margin: ${({ theme }) => `0 ${theme.spacing(0.5)}`};
  color: ${({ theme }) => theme.colors.black};
  font-family: roboto-bold, sans-serif;
`

export const $Number = styled.span`
  margin: ${({ theme }) => `0 ${theme.spacing(0.5)}`};
  color: ${({ theme }) => theme.colors.black};
  font-family: roboto-bold, sans-serif;
  font-size: 16px;
`

export const $Content = styled.div`
  width: fit-content;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1)} 0;
`

export const $Date = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
`
