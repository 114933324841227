import * as React from 'react';
import { $ActivityDates, $Date, $Deadline } from './styles';
import { BrowserLocaleDateFormatter, BrowserLocaleTimeFormatter } from '../constants/global';
export const ActivityDates = ({ assign = '', accept = '', submit = '', deadline = '' }) => {
    const validSubmission = submit ? (new Date(submit) > new Date('2000-01-01')) : false;
    const validDeadline = deadline ? (new Date(deadline) > new Date('2000-01-01')) : false;
    if (!assign && !accept && !validSubmission && !validDeadline)
        return null;
    return (React.createElement($ActivityDates, { container: true, justifyContent: 'center', alignItems: 'center' },
        assign && React.createElement($Date, null,
            "Assigned at: ",
            `${BrowserLocaleDateFormatter.format(new Date(assign))}`),
        accept && React.createElement($Date, null,
            "Accepted at: ",
            `${BrowserLocaleDateFormatter.format(new Date(accept))}`),
        submit && validSubmission && React.createElement($Date, null,
            "Submitted at: ",
            `${BrowserLocaleDateFormatter.format(new Date(submit))}`),
        deadline && validDeadline && React.createElement($Deadline, null, "Deadline"),
        deadline && validDeadline && React.createElement($Date, null, deadline ? `${BrowserLocaleDateFormatter.format(new Date(deadline))} ${BrowserLocaleTimeFormatter.format(new Date(deadline))}` : '')));
};
