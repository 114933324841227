import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { AutocompleteInput, ConfirmAction, Input, Item, NewButton, SvgIcon, User } from 'prace-common-components'
import { $Container, $ModalContainer, $UserList, $UserListItem, $Description } from './styles'
import { UsersListModalProps } from './types'
import { useSearchUsersMutation } from 'store/api/users'
import { UserSuggestion } from 'store/api/users/types'

type UserItemProps = {
  id: number,
  fullName: string,
  onRemoveClick?: (id: number) => void,
}

const UserItem : React.FC<UserItemProps> = ({ id, fullName, onRemoveClick }) => {
  const handleRemoveClick = React.useCallback(() => {
    if (onRemoveClick) onRemoveClick(id)
  }, [id, onRemoveClick])

  return (
    <$UserListItem>
      <SvgIcon
        clickable
        name='close'
        size={16}
        onClick={handleRemoveClick}
      />
      <label>{fullName}</label>
    </$UserListItem>
  )
}

const formatUserLabel = ({ firstName, lastName, email }: Partial<UserSuggestion>) => {
  return `${firstName || ''} ${lastName || ''} - ${email || ''}`
}

function useUserSuggestionWithSelectedUsers(initialUsers: User['id'][] = []) {
  const [searchUsers] = useSearchUsersMutation()
  const [userSuggestions, setUserSugestions] = React.useState<User[]>([])
  const [users, setUsers] = React.useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([])
  const [term, setTerm] = React.useState('')

  React.useEffect(() => {
    const getUsers = async () => {
      if(!term && !initialUsers.length) return
      try {
        const data = await searchUsers({
          search: term ?? undefined,
          userIds: initialUsers || [],
          page: 0,
          pageSize: 50,
          excludeCCRs: true,
        }).unwrap()
        setUserSugestions(data.users || [])
        if(initialUsers?.length) {
          const users = data.users.filter((u) => initialUsers.includes(u.id))
          setSelectedUsers((prevUsers) => [
            ...prevUsers,
            ...users
              .filter((i) => !prevUsers.map((s) => s.value).includes(i.id)) // filter users already in the previous list
              .map((u) => ({ label: formatUserLabel(u), value: u.id, visible: true })),
          ])
        }
      } catch (err) {
        console.log(err)
      }
    }
    getUsers().catch((err) => console.log(err))
  }, [term, initialUsers, searchUsers])
  
  React.useEffect(() => {
    const items: Item[] = []
    userSuggestions.forEach((userSuggestion: User) => {
      items.push({
        label: formatUserLabel(userSuggestion),
        value: userSuggestion.id,
      })
    })
    
    setUsers(items)
  }, [userSuggestions])

  return {
    selectedUsers,
    setSelectedUsers,
    setTerm,
    users,
  }
}

const SEARCH_TIMER_TIMEOUT = 500

export const UsersListModal: React.FC<UsersListModalProps> = ({
  name,
  userList,
  handleSave,
  handleDelete,
}) => {
  const {
    users, setTerm, setSelectedUsers, selectedUsers,
  } = useUserSuggestionWithSelectedUsers(userList || [])
  const [stateName, setStateName] = React.useState<StringNumber>(name || '')
  const searchTimer = React.useRef<number>()

  const handleInputChange = React.useCallback((_: any, value: string) => {
    clearTimeout(searchTimer.current)
    searchTimer.current = window.setTimeout(() => {
      setTerm(value)
    }, SEARCH_TIMER_TIMEOUT)
  }, [setTerm])

  const handleAutocompleteInput = React.useCallback((_: React.ChangeEvent<unknown>, value: unknown) => {
    const option = value as Item

    // skip when the user is already in the list (only the visible ones)
    if (selectedUsers.filter((u) => u.visible).find((u) => u.value === option.value as number)) {
      return
    }

    setSelectedUsers((prevState) => ([
      ...prevState.filter((u) => u.value !== option.value), // Remove the added one from the previous list
      {
        value: option.value as number,
        label: option.label as string,
        visible: true, // for added users we mark as visible
      },
    ]))
  }, [setSelectedUsers, selectedUsers])

  const handleRemoveUserItemClick = React.useCallback((id: number) => {
    // To Remove we just need to mark the item as `visible` true.
    // We need this to tackle the users in the visibility list, this way avoids the users from list list to appear
    // again in the list component
    setSelectedUsers((selectedUsers) => selectedUsers.map((u) => {
      if (u.value !== id) {
        return u
      }
      
      return {
        ...u,
        visible: false,
      }
    }))
  }, [setSelectedUsers])

  const handleSaveClick = React.useCallback(() => {
    // We must only send the id of the visible users
    handleSave(stateName as string, selectedUsers.filter((u) => u.visible).map((u) => u.value as number))
  }, [handleSave, stateName, selectedUsers])

  return (
    <$ModalContainer>
      <$Description>Select the users for the list</$Description>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input required title='User List Name' name='name' value={stateName} onChange={(_n, val) => setStateName(val || '')} hideItalic />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteInput
            title='Search for user'
            name='user'
            required
            items={users}
            onChange={handleAutocompleteInput}
            onInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid item>
        <$UserList>
          {selectedUsers.filter((u) => u.visible).map((selectedUser) => (
            <UserItem
              key={selectedUser.value}
              id={selectedUser.value}
              fullName={`${selectedUser.label}`}
              onRemoveClick={handleRemoveUserItemClick}
            />
          ))}
        </$UserList>
      </Grid>
      <$Container container justifyContent='space-between'>
        <Grid item xs container justifyContent='flex-start'>
          {!!handleDelete && <ConfirmAction deletion title='Delete User List' description='This action will remove the list from every cutoff role using it.' onConfirm={handleDelete}>
            <NewButton error variant='outlined' onClick={handleDelete}>Delete</NewButton>
          </ConfirmAction>}
        </Grid>
        <Grid item xs={3} container justifyContent='flex-end'>
          <NewButton disabled={!stateName || !selectedUsers.length} onClick={handleSaveClick}>
            Save
          </NewButton>
        </Grid>
      </$Container>
    </$ModalContainer>
  )
}
