import { BrowserLocaleDateFormatter, FORM_ELEMENT_TYPES, getFlowElement } from '../';
import { getConditionsValues } from './getConditionValues';
export const isEmail = (email) => {
    return RegExp(/^(([^\\<>()\[\]\.,;:\s@\"]+(\.[^\\<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^\\<>()[\]\.,;:\s@\"]+\.)+[^\\<>()[\]\.,;:\s@\"]{2,})$/i).test(email);
};
export const isValidURL = (url) => {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
    return re.test(String(url).toLowerCase());
};
export const isValidPhoneNumber = (number) => {
    const re = /^(\+|00)[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{6,14}$/;
    return re.test(number);
};
export const validateFlowElement = (call, element, stepId, resources, instanceLength = 0, groupInstance, elementValue) => {
    const { elementType, config, title } = element;
    const value = elementValue ? elementValue : element.value;
    const condition = getConditionsValues(call, element, stepId, groupInstance);
    const { visible, valid, required, editable } = condition;
    const dumbComponents = [
        FORM_ELEMENT_TYPES.TITLE,
        FORM_ELEMENT_TYPES.INSTRUCTIONS,
        FORM_ELEMENT_TYPES.SPACE,
        FORM_ELEMENT_TYPES.DIVIDER,
        FORM_ELEMENT_TYPES.GROUP,
    ];
    let resource = undefined;
    if (!(visible && editable))
        return { error: false, errorMsg: '', condition, resource };
    if (elementType === FORM_ELEMENT_TYPES.GROUP) {
        const min = config?.min;
        const max = config?.max;
        if (min && (instanceLength < Number(min)))
            return {
                error: true,
                errorMsg: `A minimum of ${min} ${title}s is needed.`,
                condition,
                resource,
            };
        if (max && (instanceLength > Number(max)))
            return {
                error: true,
                errorMsg: `There can't be more than ${max} ${title}s.`,
                condition,
                resource,
            };
    }
    if (dumbComponents.includes(elementType))
        return {
            error: false,
            errorMsg: '',
            condition,
            resource,
        };
    if (elementType === FORM_ELEMENT_TYPES.PARTITION_RESOURCES) {
        let partitionValue;
        if (groupInstance) {
            const childInstance = groupInstance
                .childInstances?.find((child) => child.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION);
            partitionValue = childInstance?.value;
        }
        else {
            const { form } = getFlowElement(call, element.id);
            const selectPartition = form?.flow.find((element) => element.elementType === FORM_ELEMENT_TYPES.SELECT_PARTITION);
            partitionValue = selectPartition?.value;
        }
        resource = resources?.find((resource) => resource.partition.id == partitionValue);
        if (!resource)
            return { error: false, errorMsg: '', condition };
        const { min, max, fixed } = resource;
        if (!fixed && (value && !!min && (min >= 0) && Number(value) < Number(min))) {
            return {
                error: true,
                errorMsg: `The value must be higher than ${min}`,
                condition,
                resource,
            };
        }
        if (!fixed && (value && !!max && (max >= 0) && Number(value) > Number(max))) {
            return {
                error: true,
                errorMsg: `The value must be lower than ${max}.`,
                condition,
                resource,
            };
        }
    }
    if (!valid)
        return {
            error: true,
            errorMsg: 'This value is not valid.',
            condition,
            resource,
        };
    if (((required || config?.required) &&
        (Array.isArray(value) ? (!value.length || !value.join('')) : (!value && (value !== 0)))))
        return {
            error: true,
            errorMsg: 'This is mandatory.',
            condition,
            resource,
        };
    const min = config?.min;
    const max = config?.max;
    if (elementType === FORM_ELEMENT_TYPES.TEXT_AREA) {
        if (min && value?.length < Number(min))
            return {
                error: true,
                errorMsg: `The text must have a minimum of ${min} characters.`,
                condition,
                resource,
            };
        if (max && value?.length > Number(max))
            return {
                error: true,
                errorMsg: `The text must have less than ${max} characters.`,
                condition,
                resource,
            };
    }
    if (elementType === FORM_ELEMENT_TYPES.DATE) {
        const dateValue = new Date(value || '').getTime();
        if (min) {
            const minDate = new Date(min).getTime();
            if (!!dateValue && dateValue < minDate)
                return {
                    error: true,
                    errorMsg: `The value must be after ${BrowserLocaleDateFormatter.format(new Date(min))}.`,
                    condition,
                    resource,
                };
        }
        if (max) {
            const maxDate = new Date(max).getTime();
            if (!!dateValue && dateValue > maxDate)
                return {
                    error: true,
                    errorMsg: `The value must be before ${BrowserLocaleDateFormatter.format(new Date(max))}.`,
                    condition,
                    resource,
                };
        }
    }
    else {
        if (((value || value === 0) && min && (min >= 0) && Number(value) < Number(min)) || (!value && (value !== 0) && min && (min >= 0)))
            return {
                error: true,
                errorMsg: `The value must be above ${min}.`,
                condition,
                resource,
            };
        if (((value || value === 0) && max && (max >= 0) && Number(value) > Number(max)) || (!value && (value !== 0) && max && (max >= 0)))
            return {
                error: true,
                errorMsg: `The value must be below ${max}.`,
                condition,
                resource,
            };
    }
    if (elementType === FORM_ELEMENT_TYPES.EMAIL && value && !isEmail(String(value)))
        return {
            error: true,
            errorMsg: 'Insert a valid email.',
            condition,
            resource,
        };
    if (elementType === FORM_ELEMENT_TYPES.PHONE_NUMBER && value &&
        !isValidPhoneNumber(String(value).replace(/[|]/g, ''))) {
        return {
            error: true,
            errorMsg: 'Insert a valid phone number.',
            condition,
            resource,
        };
    }
    return { error: false, errorMsg: '', condition, resource };
};
