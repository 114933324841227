import styled from 'styled-components'
import { $Badge } from 'prace-common-components'

export const $Home = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: 50px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
`

export const $HeaderBack = styled.div`
  width: 100%;
  background-color: #FBFBFB;
`

export const $Header = styled.div`
  width: 100%;
  color: #333333;
  font-weight: 300;
  font-size: 40px;
  margin: 0 auto;
  padding: 42px 30px;
`

export const $ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const $Actions = styled.div`
  margin-left: auto;
  padding: 18px;
`

export const $ModalTitle = styled.p`
  flex: 0;
  margin-bottom: 50px;
  margin-left: 20px;
`

export const $Content = styled.div`
  padding: 0px 25px;
  min-width: 400px;
`

export const $Chip = styled($Badge)`
  margin: 0;
  max-height: 25px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`

export const $ChipContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .simplebar-content-wrapper {
    display: flex;
    align-items: center;
  }
`

export const $RowContainer = styled.div`
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  >* {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
`

export const $SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
  button {
    width: 150px;
  }
`
