import { GROUP_TYPES } from 'store/calls/types'
import sandboxConfig from '../../theme.config.json'
import { SandboxConfig } from './types.js'
import { FORM_ELEMENT_TYPES, ROLES, ROLE_NAME } from 'prace-common-components'

export const __LOCAL__ = ENV === 'local'
export const __PROD__ = APP_ENV === 'production'
export const CDN = CDN_URL
export const CDN_AVATARS = `${CDN}/avatars`

export const PORTAL = 'admin_portal'
export const ORGANIZATION = ORGANIZATION_NAME

export const SHOW_CINES = HAS_CINES ? /true/.test(HAS_CINES) : false

export const loginEmail = window.localStorage.getItem('email') || ''

export const config: SandboxConfig = sandboxConfig

export const TOKEN_KEY = 'a_t'
// NOTE: this is only the time the cookie will be set, the actual expiration of the token is
// dictated by the server
export const TOKEN_MAX_AGE = 1 // <-- 1 day

export const addElements = [
  {
    text: 'Text', id: FORM_ELEMENT_TYPES.TEXT, elementType: FORM_ELEMENT_TYPES.TEXT,
  },
  {
    text: 'Textarea', id: FORM_ELEMENT_TYPES.TEXT_AREA, elementType: FORM_ELEMENT_TYPES.TEXT_AREA,
  },
  {
    text: 'Title', id: FORM_ELEMENT_TYPES.TITLE, elementType: FORM_ELEMENT_TYPES.TITLE,
  },
  {
    text: 'Email', id: FORM_ELEMENT_TYPES.EMAIL, elementType: FORM_ELEMENT_TYPES.EMAIL,
  },
  {
    text: 'Instructions', id: FORM_ELEMENT_TYPES.INSTRUCTIONS, elementType: FORM_ELEMENT_TYPES.INSTRUCTIONS,
  },
  {
    text: 'Blank Space', id: FORM_ELEMENT_TYPES.SPACE, elementType: FORM_ELEMENT_TYPES.SPACE,
  },
  {
    text: 'Divider', id: FORM_ELEMENT_TYPES.DIVIDER, elementType: FORM_ELEMENT_TYPES.DIVIDER,
  },
  {
    text: 'Select Partition', id: FORM_ELEMENT_TYPES.SELECT_PARTITION, elementType: FORM_ELEMENT_TYPES.SELECT_PARTITION,
  },
  {
    text: 'Partition Resources', id: FORM_ELEMENT_TYPES.PARTITION_RESOURCES, elementType: FORM_ELEMENT_TYPES.PARTITION_RESOURCES,
  },
  {
    text: 'Select', id: FORM_ELEMENT_TYPES.SELECT, elementType: FORM_ELEMENT_TYPES.SELECT,
  },
  {
    text: 'MultiSelect', id: FORM_ELEMENT_TYPES.MULTISELECT, elementType: FORM_ELEMENT_TYPES.MULTISELECT,
  },
  {
    text: 'MultiText', id: FORM_ELEMENT_TYPES.MULTITEXT, elementType: FORM_ELEMENT_TYPES.MULTITEXT,
  },
  {
    text: 'Checkbox', id: FORM_ELEMENT_TYPES.CHECKBOX, elementType: FORM_ELEMENT_TYPES.CHECKBOX,
  },
  {
    text: 'Toggle', id: FORM_ELEMENT_TYPES.TOGGLE, elementType: FORM_ELEMENT_TYPES.TOGGLE,
  },
  {
    text: 'Upload', id: FORM_ELEMENT_TYPES.UPLOAD, elementType: FORM_ELEMENT_TYPES.UPLOAD,
  },
  {
    text: 'Number', id: FORM_ELEMENT_TYPES.NUMBER, elementType: FORM_ELEMENT_TYPES.NUMBER,
  },
  {
    text: 'Phone number', id: FORM_ELEMENT_TYPES.PHONE_NUMBER, elementType: FORM_ELEMENT_TYPES.PHONE_NUMBER,
  },
  {
    text: 'Date', id: FORM_ELEMENT_TYPES.DATE, elementType: FORM_ELEMENT_TYPES.DATE,
  },
  {
    text: 'Research Field', id: GROUP_TYPES.RESEARCH_FIELD, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Scientific reviewer', id: GROUP_TYPES.SCIENTIFIC_REVIEWER, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Team Members', id: GROUP_TYPES.TEAM_MEMBERS, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Partitions', id: GROUP_TYPES.PARTITIONS, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Partitions Award', id: GROUP_TYPES.PARTITIONS_ALLOCATION, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Add Code', id: GROUP_TYPES.ADD_CODE, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
  {
    text: 'Review Upload', id: GROUP_TYPES.REVIEW_UPLOAD, elementType: FORM_ELEMENT_TYPES.GROUP,
  },
]

export enum STEPS {
  //Original Steps
  PROJECT_APPLICATION = 'Project Application',
  TECHNICAL_REVIEW = 'Technical Assessment',
  RAPPORTEURS = 'Rapporteurs',
  SCIENTIFIC_REVIEW = 'Scientific Evaluation',
  RESPONSE_TO_REVIEWS = 'Response to Reviews',
  INDIVIDUAL_REPORT = 'Individual Report',
  CONSOLIDATED_REPORT = 'Consolidated Report',
  RESOURCES_ALLOCATION_DECISION = 'Resources Allocation Decision',
  FINAL_STATEMENT = 'Final Statement',
  RESPONSE_TO_ALLOCATION = 'Response to Allocation',
  COMPLETED = 'Completed',
  //Benchmark Steps
  APPLICANT_SUBMISSION = 'Applicant Submission',
  FINAL_REPORT = 'Submit Final Report',
  //Development Steps
  ASSIGNING_TECHNICAL_REVIEWS = 'Assigning Technical Reviews',
  TECHNICAL_ASSESSMENT_CCR_SUPPORT = 'Technical Assessment - CCR Support',
  //EUROHPC Regular
  FINAL_FEEDBACK = 'Final Feedback',
}

export enum ELEMENTS {
  SUBMISSION_TYPE = 'Submission type',
  PROGRESS_FINAL_REPORT_PDF= 'Progress/Final Report .pdf',
}

export enum APPLICATION_FIELDS {
  DATE = 'submissionDate',
  ID = 'uid',
  STATUS = 'status',
  CALL_TITLE = 'callTitle',
  PI_FIRST_NAME = 'firstName',
  PI_LAST_NAME = 'lastName',
  PI_NAME = 'name',
  PI_AFFILIATION = 'piAffiliation',
  RESEARCH_FIELD_GROUP = 'researchGroup',
  RESEARCH_FIELD_TITLE = 'researchTitle',
  PARTITION = 'partition',
  SUBMITTED = 'submittedAt',
}

export const RoleNameItems = Object.values(ROLES).map((role) => { return { label: ROLE_NAME[role], value: role }})

export enum DASHBOARD_STATS {
  PENDING_SUBMISSION = 'pendingSubmission',
  MISSED_SUBMISSION = 'missedSubmission',
  SUBMITTED = 'submitted',
  REQUEST_CHANGES = 'requestChanges',
  PENDING_ACCEPTANCE = 'pendingAcceptance',
  PENDING_APPROVAL = 'pendingApproval',
  APPROVED = 'approved',
  CONFLICT_INTEREST = 'conflictInterest',
}

export enum DASHBOARD_STATS_TEXTS {
  pendingSubmission = 'Pending Submissions',
  missedSubmission = 'Expired Submissions',
  submitted = 'Submitted Assignments',
  requestChanges = 'Request Changes',
  pendingAcceptance = 'Pending Acceptance',
  pendingApproval = 'Pending Approval',
  approved = 'Approved Assignments',
  conflictInterest = 'Conflict of Interest',
}

export enum CONDITION_EFFECT {
  lesser = '<',
  higher = '>',
  equal = '===',
  different = '!==',
  non_empty = '!!',
}

export enum USER_FIELDS {
  ID = 'ID',
  NAME = 'Name',
  EMAIL = 'E-mail',
  ROLE = 'Role'
}

export enum CENTER_FIELDS {
  ID = 'ID',
  NAME = 'Name',
  CCR = 'CCR',
}

export enum PARTITION_FIELDS {
  ID = 'ID',
  NAME = 'Name',
  ENABLED = 'Enabled',
}

export enum CALL_FIELDS {
  DATE = 'Submission date',
  ID = 'ID',
  TITLE = 'Title',
  PUBLISHED = 'Published',
  START = 'Start Allocation',
  END = 'End Allocation',
  DUPLICATE = 'Duplicate',
  APPLICATIONS_REPORT = 'Applications Report',
  PAYMENT_REPORT = 'Payment Report',
  FILES_BUNDLE = 'Files bundle',
}

export enum ReviewApproveState {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  REQUEST_CHANGES = 'request_changes',
}

export const Routes = {
  HOME: '/',
  TEMPLATE_MANAGER: '/templates',
  TEMPLATE_SEARCH: (page: StringNumber = '', search = '', orderBy = '') => `${Routes.TEMPLATE_MANAGER}?page=${page}&order=${orderBy}${search}`,
  TEMPLATE: (id: StringNumber = '') => `${Routes.TEMPLATE_MANAGER}/${id}`,
  HOME_SEARCH: (page: StringNumber = '', search = '', orderBy = '') => `${Routes.APPLICATIONS}?page=${page}&order=${orderBy}${search}`,
  LOGIN: '/auth/login',
  CALLS: '/calls',
  ADMINS: '/admins',
  CALL: (id: StringNumber = '') => `${Routes.CALLS}/${id}`,
  APPLICATION: (uid: StringNumber = '') => `${Routes.APPLICATIONS}/${uid}`,
  APPLICATIONS: '/applications',
  USER_TABLE: '/users',
  USERS: (id: StringNumber = '') => `/users/${id}`,
  FORGOT_PASSWORD: '/auth/forgot',
  FORGOT_PASSWORD_SENT: '/auth/forgot/sent',
  FORGOT_PASSWORD_RESET: '/auth/reset/:token',
  EMAIL_VERIFY: '/auth/confirm/email/:token',
  PARTITION_MANAGER: '/pm',
  LEGACY: '/legacy',
  CINES: (id: StringNumber = '') => `/legacy/${id}`,
}

