import * as React from 'react'
import { Grid } from '@material-ui/core'
import { Title, BrowserLocaleDateFormatter, ActionsColumn, DataRow, newTheme, ConfirmModal, NestedMenu, NewButton, FlowCall, FILTER_TYPE, ExportableTable, NotificationType, ROLES, DataFilter } from 'prace-common-components'
import { $Calls, $SvgIcon } from './styles'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import { Routes } from 'constants/global'
import { EditCallModal } from 'components/EditCallModal'
import { $Chip, $ChipContainer, $RowContainer } from './styles'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useCloneCallMutation, useCreateCallMutation, useDeleteCallMutation, useGetCallsQuery, useLazyGetCallsQuery } from 'store/api/calls'
import { useLazyDownloadApplicationReportQuery, useLazyDownloadCallBundleQuery } from 'store/api/downloads'
import { RootState } from 'store'
import { TABLE_CALL_FIELDS } from './types'

//const ACTIVE_CALLS = 'Only active'

type AllFilterType = { page: number; orderBy: {[key: string]: 'ASC' | 'DESC'}; searchBy: {[key: string]: string}}

const limit = 999

const menuItems = [
  {id: 0, label: <><$SvgIcon name='altEdit' size={16} />Edit</>},
  {id: 1, label: <><$SvgIcon name='content-copy' size={16} />Duplicate</>},
  {id: 2, label: <><$SvgIcon name='download' size={16} />Application Report (.csv)</>},
  {id: 3, label: <><$SvgIcon name='download' size={16} />Application Report (.xlsx)</>},
  {id: 4, label: <><$SvgIcon name='download' size={16} />Files Bundle</>},
  {id: 5, label: <><$SvgIcon name='delete' size={16} />Delete</>},
]

export const Calls: React.FC<{isMobile?: boolean}> = ({ isMobile }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const role = useAppSelector((state: RootState) => state.auth.user?.role)
  const [deleteCall] = useDeleteCallMutation()
  const [createCall] = useCreateCallMutation()
  const [cloneCall] = useCloneCallMutation()
  const [downloadBundle] = useLazyDownloadCallBundleQuery()
  const [downloadReport] = useLazyDownloadApplicationReportQuery()
  const [getCalls] = useLazyGetCallsQuery()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [duplicateId, setDuplicateId] = React.useState<number>()
  const [openDelete, setOpenDelete] = React.useState<boolean>(false)
  const [selectedId, setSelectedId] = React.useState<number>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setOpen] = React.useState<'create' | 'duplicate' | undefined>(searchParams.get('open') as 'create' | 'duplicate' | undefined)
  const [allFilters, setAllFilters] = React.useState<AllFilterType>({
    searchBy: {},
    orderBy: {[TABLE_CALL_FIELDS.SUBMITTED]: 'DESC'},
    page: 1,
  })
  const { data: calls, refetch } = useGetCallsQuery(
    { offset: limit * (allFilters.page - 1), limit }, { refetchOnMountOrArgChange: true })
  const callsData = calls?.data || []

  /* TODO: Filtering is done in the FE, pass this to the BE */
  const filteredRows = callsData.filter((call) => {
    const { searchBy } = allFilters
    let isFiltered = true
    for(const key in searchBy) {
      if(searchBy[key] && call[key as keyof FlowCall] && !String(call[key as keyof FlowCall])?.toLowerCase()
        .includes(searchBy[key].toLowerCase())) {
        isFiltered = false
        break
      }
    }
    return isFiltered
  })

  const total = filteredRows.length || 0


  React.useEffect(() => {
    setOpen(searchParams.get('open') as 'create' | 'duplicate' | undefined)
  }, [searchParams, setOpen])

  const handleDownloadApplicationReport = async (id: FlowCall['id'], format: 'csv' | 'xlsx') => {
    try {
      await downloadReport({ id, format }).unwrap()
      dispatch({ type: 'notification', payload: { timer: 5000, type: NotificationType.success, msg: 'The applications report will be sent to your email address. This could take up to 5 minutes.' } })
    } catch (err) {
      console.log(err)
    }
  }

  const handleDownloadBundleClick = async (callId: FlowCall['id']) => {
    try {
      await downloadBundle(callId).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const handleItemClick = (id: StringNumber) => {
    setAnchorEl(null)
    if(selectedId === undefined) return
    switch(id) {
      case 0:
        navigate(Routes.CALL(selectedId))
        break
      case 1:
        setDuplicateId(selectedId)
        setSearchParams({ open: 'duplicate' })
        break
      case 2:
        handleDownloadApplicationReport(selectedId, 'csv')
        break
      case 3:
        handleDownloadApplicationReport(selectedId, 'xlsx')
        break
      case 4:
        handleDownloadBundleClick(selectedId)
        break
      default:
        setOpenDelete(true)
    }
  }

  const handleDelete = async () => {
    if(selectedId === undefined) return
    try {
      await deleteCall(selectedId).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Call deleted' } })
      setOpenDelete(false)
      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  const handleClick = (e: React.MouseEvent<any>, id: StringNumber) => {
    setAnchorEl(e.currentTarget)
    setSelectedId(Number(id))
  }

  const handleCreateCall = async (call: FlowCall) => {
    try {
      const data = await createCall(call).unwrap()
      dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Call created' } })
      setSearchParams()
      navigate(Routes.CALL(data.id))
    } catch (err) {
      console.log(err)
    }
  }

  const handleDuplicate = async (call: FlowCall, cloneNotifications: boolean) => {
    if(duplicateId !== undefined) {
      try {
        const data = await cloneCall({ ...call, cloneNotifications, id: duplicateId }).unwrap()
        dispatch({ type: 'notification', payload: { type: NotificationType.success, msg: 'Call created' } })
        setSearchParams()
        navigate(Routes.CALL(data.id))
      } catch (err) {
        console.log(err)
      }
    }
  }

  /** change current table page */
  const handleChangePage = (newPage: number) => {
    changeFilters(undefined, undefined, newPage)
  }
  const changeFilters = React.useCallback((
    order?: { [key: string]: 'ASC' | 'DESC' },
    search?: { [key: string]: string },
    page: number = 1,
  ) => {
    setAllFilters((prevFilters: AllFilterType) => {
      const newOrder = order ? order : prevFilters.orderBy
      const newSearch = search ? search : prevFilters.searchBy
      return { page, searchBy: newSearch, orderBy: newOrder }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  const onSortColumn = (columnKey: string, direction?: 'ASC' | 'DESC') => {
    changeFilters({[columnKey]: direction as 'ASC' | 'DESC'})
  }

  const onFilters = (search: DataFilter) => {
    changeFilters(undefined, search)
  }

  const columns = [
    {
      key: TABLE_CALL_FIELDS.UID,
      type: FILTER_TYPE.TEXT,
      name: 'Call ID',
      formatter(props: { row: DataRow }) {
        const { id, uid } = props.row
        return role === ROLES.ADMIN ? <$RowContainer data-simplebar>
          <Link to={Routes.CALL(id)}>{uid}</Link>
        </$RowContainer> : uid
      },
    },
    { key: TABLE_CALL_FIELDS.TITLE, type: FILTER_TYPE.TEXT, name: 'Call Name' },
    {
      key: TABLE_CALL_FIELDS.STATUS,
      type: FILTER_TYPE.SELECT,
      name: 'Status',
      selectOptions: [
        {value: 'Published', label: 'Published'},
        {value: 'Draft', label: 'Draft'},
      ],
      formatter(props: { row: DataRow }) {
        const { status } = props.row
        const draft = status === 'Draft'
        return <$ChipContainer data-simplebar>
          <$Chip color={draft ? newTheme.colors.neutral.light : newTheme.colors.secondaries.light}>{status}</$Chip>
        </$ChipContainer>
      },
    },
    { key: TABLE_CALL_FIELDS.CUTOFF_START, type: FILTER_TYPE.NONE, noSort: true, name: 'On-Going Cut-off Start' }, //TODO: Switch FILTER_TYPE.DATE when ready
    //{ key: 'cutoff-end', type: FILTER_TYPE.NONE, name: 'On-Going Cut-off End' },
    //TODO: Switch FILTER_TYPE.DATE when ready
    { key: TABLE_CALL_FIELDS.SUBMITTED, type: FILTER_TYPE.NONE, noSort: true, name: 'Submitted Proposals' }, // TODO: Will have FILTER_TYPE.NUMBER
    { key: TABLE_CALL_FIELDS.ACCEPTED, type: FILTER_TYPE.NONE, noSort: true, name: 'Accepted Proposals' }, // TODO: Will have FILTER_TYPE.NUMBER
    { key: 'actions', type: FILTER_TYPE.NONE, noSort: true, name: 'Actions', width: 35 },
  ]

  const rows = callsData.map((call) => {
    return { //TODO: Insert Ongoing cutoff start and end
      ...call,
      //TODO: Determine the ongoing cutoff
      'cutoff-start': call.cutoffs?.length && call.cutoffs[0]?.start ? BrowserLocaleDateFormatter.format(new Date(call.cutoffs[0].start)) : '',
      /* 'cutoff-end': call.cutoffs?.length && call.cutoffs[0]?.end ?
        BrowserLocaleDateFormatter.format(new Date(call.cutoffs[0].end)) : '', */
      status: call.published ? 'Published' : 'Draft',
      submitted: '-', //TODO: Should come from BE
      accepted: '-', //TODO: Should come from BE
      actions: <ActionsColumn handleClick={(e: React.MouseEvent<any>) => handleClick(e, call.id)}/>,
    }
  })

  const exportAll = async () => {
    try {
      const { data } = await getCalls({ limit: 9999, offset: 0 }).unwrap()
      const rows = data.map((call) => ({
        ...call,
        'cutoff-start': call.cutoffs?.length && call.cutoffs[0]?.start ? BrowserLocaleDateFormatter.format(new Date(call.cutoffs[0].start)) : '',
        status: call.published ? 'Published' : 'Draft',
        submitted: '-',
        accepted: '-',
        actions: <></>,
      }))
      return rows as DataRow[]
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const callCreate = open === 'create'
  const isCallOpen = callCreate ||  open === 'duplicate'

  return  (
    <>
      <$Calls>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item xs>
            <Title alternate fontSize={22}>Calls</Title>
          </Grid>
          {role === ROLES.ADMIN &&
            <Grid item>
              <NewButton
                creator
                disabled={isMobile}
                onClick={() => setSearchParams({ open: 'create' })}
                onAuxClick={() => window.open(`${window.location.href}?open=create`, '_blank')}
              >
              Create New Call
              </NewButton>
            </Grid>}
        </Grid>
        <ExportableTable
          title='Calls'
          rows={rows}
          columns={columns}
          total={total}
          page={allFilters.page}
          rowsPerPage={limit}
          onChangePage={handleChangePage}
          noFilterType
          noMultipleSorting
          exportAll={exportAll}
          onSortColumn={onSortColumn}
          onFilters={onFilters}
          initFilters={allFilters.searchBy}
        />
      </$Calls>

      <NestedMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={menuItems}
        handleItemClick={handleItemClick}
      />

      {isCallOpen &&
        <EditCallModal
          modalTitle={callCreate ? 'Create Call' : 'Create New Call'}
          cloning={!callCreate}
          onClose={() => setSearchParams()}
          call={callCreate ? undefined : callsData.find((call) => call.id === duplicateId)}
          handleUpdateCall={callCreate ? handleCreateCall : handleDuplicate}
        />
      }

      {openDelete &&
        <ConfirmModal
          open
          title='Delete Call'
          description='Deleting a call will have impact on templates associated with it.'
          onConfirm={handleDelete}
          onClose={() => setOpenDelete(false)}
        />
      }
    </>
  )
}
