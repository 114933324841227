import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { config, Routes, __PROD__, CDN, SHOW_CINES } from 'constants/global'
import { initTabs } from './config'
import { ExpiredToken, ROLES, useCheckMobileScreen, Footer, Navigation, TabProps, DropdownItem, TopBanner } from 'prace-common-components'
import { UserInfoProvider } from 'components/UserInfo'
import { LoadingWrapper } from 'components/Loading'
import { NotificationWrapper } from 'components/NotificationWrapper'
import { DashRoutes, routes } from './routes'
import { $Content } from './styles'
import { useLogoutMutation, useRefreshMutation } from 'store/api/auth'
import { RootState } from 'store'
import { useAppSelector } from 'store/hooks'

export const App = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth)
  const user = useAppSelector((state: RootState) => state.auth.user)
  const theme = useAppSelector((state: RootState) => state.organization.theme)
  const [logout] = useLogoutMutation()
  const [refreshToken, { isSuccess, isLoading, isUninitialized: unloadedRefresh }] = useRefreshMutation()
  const [tabs, setTabs] = useState<TabProps[]>([])
  const [initialTab, setInitialTab] = useState(0)
  const [selectedTab, selectTab] = useState(0)
  const [navigations, setNavigations] = useState(true)
  const [openExpire, setOpenExpire] = useState<boolean>(false)
  const isMobile = useCheckMobileScreen()

  useEffect(() => {
    const refresh = async () => {
      try {
        await refreshToken().unwrap()
      } catch (err) {
        console.log(err)
      }
    }
    if (!user && isAuth) refresh().catch((err) => console.log(err))
  }, [isAuth, refreshToken, user])


  useEffect(() => {
    const scrollToTop= () => {
      try {
        // Trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        document.body.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } catch (error) {
        document.body.scrollTo(0, 0) // fallback for older browsers
      }
    }
    document.body.addEventListener('scrollToTop', scrollToTop)
    return () => document.body.removeEventListener('scrollToTop', scrollToTop)
  }, [])

  useEffect(() => {
    const onExpire= () => {
      if(location.pathname !== Routes.LOGIN && user) {
        setOpenExpire(true)
      } else { 
        if(location.pathname !== Routes.LOGIN)
          navigate(Routes.LOGIN)
      }
    }
    document.body.addEventListener('expired', onExpire)
    return () => document.body.removeEventListener('expired', onExpire)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user])


  useEffect(() => {
    if(location.pathname.includes('/auth'))
      setNavigations(false)
    else
      setNavigations(true)
  }, [location.pathname])

  /** sets navigation tabs */
  useEffect(() => {
    if (user) {
      if(user.role === ROLES.SUPER_ADMIN) {
        setTabs([{ label: 'Admins', route: Routes.ADMINS }])
        setInitialTab(0)
        return
      }
      const newTabs = !SHOW_CINES ? initTabs.filter((t) => t.route !== Routes.LEGACY) : initTabs
      for (const [idx, tab] of newTabs.entries()) {
        if (location.pathname.includes(tab.route)) {
          setInitialTab(idx)
        }
      }
      setTabs(newTabs)
    }
  }, [user, location.pathname])

  const handleTabChange = (route: string) => {
    /* This will clear the submissions filter */
    if((route === Routes.APPLICATIONS) && (location.pathname?.includes(Routes.APPLICATIONS))) {
      window.localStorage.removeItem('dashboardState')
      window.location.reload()
    } else {
      navigate(route)
    }
  }

  const handleLogout = async () => {
    try {
      await logout().unwrap()
      navigate(Routes.LOGIN)
    } catch (err) {
      console.log(err)
    }
  }

  const userMenuItems: DropdownItem[] = [
    { label: 'Logout', align: 'flex-end', onClick: () => handleLogout()},
  ]

  const onLogin = () => {
    setOpenExpire(false)
    navigate(Routes.LOGIN)
  }

  const waiting = isLoading || (isAuth && !user && unloadedRefresh)

  const waitingUser = isSuccess && !user

  const footerLinks = [
    {
      text: 'Contact',
      href: theme?.contact.link || 'https://prace-ri.eu/contact/',
    },
  ]

  return (
    <>
      {!__PROD__ && <TopBanner message='This is a development environment!'/>}
      {navigations && !waiting && !waitingUser && (
        <>
          <Navigation
            logo={`${CDN}/logoSquare.svg`}
            key='app-navigation'
            tabs={tabs}
            initialTab={initialTab}
            onTabChange={handleTabChange}
            name={config.portalName}
            selectedTab={selectedTab}
            selectTab={selectTab}
            userMenuItems={userMenuItems}
            user={user}
            isMobile={isMobile}
            handleLogoClick={() => handleTabChange(Routes.HOME)}
          />
          <UserInfoProvider>
            <$Content direction='column'>
              <DashRoutes isMobile={isMobile} />
            </$Content>
          </UserInfoProvider>
        </>)}
      <LoadingWrapper />
      <ExpiredToken open={openExpire} onLogin={onLogin}/>
      {navigations && <Footer
        logo={`${CDN}/logoSquare.svg`}
        name={theme?.organization.name || 'PARTNERSHIP FOR ADVANCED COMPUTING IN EUROPE'}
        homepage={theme?.organization.link || 'https://prace-ri.eu/'}
        links={footerLinks}
        desc={`© ${new Date().getFullYear()} PRACE. All rights reserved. Proudly made in Europe. v${PKG_VERSION}`}
      />}

      {!navigations && !waiting && !waitingUser && routes()}
      {/**@ts-ignore */}
      {ReactDOM.createPortal(<NotificationWrapper />, document.getElementsByTagName('body')[0])}
    </>
  )
}
