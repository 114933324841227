import { APPLICATION_FIELDS, DASHBOARD_STATS, DASHBOARD_STATS_TEXTS, Routes } from 'constants/global'
import React, { useEffect, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { $Chip, $Home, $ChipContainer, $RowContainer, $SubTitleContainer } from './styles'
import { getSearchQuery, Title, DataRow, newTheme, DataFilter, FILTER_TYPE, ExportableTable, FLOW_STATUS, Button, BrowserLocaleDateFormatter, BrowserLocaleTimeFormatter } from 'prace-common-components'
import { useQuery } from 'util/useQuery'
import { useSearchApplicationsMutation } from 'store/api/applications'
import { GetApplicationsResponse } from 'store/api/applications/types'

const limit = 10

type AllFilterType = { page: number; orderBy: {[key: string]: 'ASC' | 'DESC'}; searchBy: {[key: string]: string}}

export const Home = () => {
  const navigate = useNavigate()
  const { query } = useQuery()
  const [dashboardState, setDashboardState] = useState<any>()
  const pageQuery = parseInt(query.get('page') || '1')
  const order = query.get('order') as unknown as APPLICATION_FIELDS || ''
  const orderDirection: 'ASC' | 'DESC' = query.get('direction') === 'ASC' ? 'ASC' : 'DESC'
  const [searchApplications] = useSearchApplicationsMutation()
  const [applications, setApplications] = React.useState<GetApplicationsResponse>({
    data: [],
    total: 0,
  })
  const applicationsData = applications?.data || []
  const total = applications?.total || 0

  const [allFilters, setAllFilters] = useState<AllFilterType>({
    searchBy: getSearchQuery(query, APPLICATION_FIELDS),
    orderBy: order ? {[order as APPLICATION_FIELDS]: orderDirection} : {[APPLICATION_FIELDS.SUBMITTED]: 'DESC'},
    page: pageQuery,
  })

  /** handles applications search on api */
  useEffect(() => {
    const getApplications = async () => {
      try {
        const savedState = window.localStorage.getItem('dashboardState')
        if (savedState)
          setDashboardState(JSON.parse(savedState))
        
        const ids = savedState ? JSON.parse(savedState).ids : []

        const offset = limit * (pageQuery - 1)
        const data = await searchApplications({ ...allFilters, limit, offset, ids }).unwrap()
        setApplications(data)
      } catch (err) {
        console.log(err)
      }
    }
    getApplications().catch((err) => console.log(err))
  }, [searchApplications, allFilters, pageQuery])

  const cleanDashboardFilter = () => {
    window.localStorage.removeItem('dashboardState')
    setDashboardState(undefined)
    urlHandler(undefined, undefined, 1)
  }

  const urlHandler = useCallback((
    order?: { [key: string]: 'ASC' | 'DESC' },
    search?: DataFilter,
    page = 1,
  ) => {
    setAllFilters((prevFilters) => {
      const newOrder = order ? order : prevFilters.orderBy
      let orderByField = ''
      Object.keys(newOrder).forEach((key: string) => {
        if(newOrder[key]) orderByField = orderByField + `${key}&direction=${newOrder[key]}`
      })
      const newSearch = search ? search : prevFilters.searchBy
      let searchStr = ''
      Object.keys(newSearch).forEach((key: string) => {
        searchStr = searchStr + `&${key}=${newSearch[key as keyof typeof newSearch]}`
      })
      navigate(Routes.HOME_SEARCH(page, searchStr, orderByField))
      return { page, searchBy: newSearch, orderBy: newOrder}
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, query])

  /** change current table page */
  const handleChangePage = (newPage: number) => {
    if(newPage !== pageQuery) urlHandler(undefined, undefined, newPage)
  }

  const onSortColumn = (columnKey: string, direction?: 'ASC' | 'DESC') => {
    urlHandler({[columnKey]: direction || 'DESC'})
  }

  const onFilters = (search: DataFilter) => {
    urlHandler(undefined, search)
  }

  const columns = [
    {
      key: APPLICATION_FIELDS.ID,
      type: FILTER_TYPE.TEXT,
      name: 'Application ID',
      width: 200,
      formatter(props: { row: DataRow }) {
        const { id, uid, status } = props.row

        return (
          <$RowContainer data-simplebar>
            <Link to={Routes.APPLICATION(uid)}>
              {status === FLOW_STATUS.DRAFT ? `DRAFT-${id}`: uid}
            </Link>
          </$RowContainer>
        )
      },
    },
    { key: APPLICATION_FIELDS.CALL_TITLE, type: FILTER_TYPE.TEXT, name: 'Call Name' },
    { key: APPLICATION_FIELDS.STATUS, type: FILTER_TYPE.TEXT, name: 'Status', width: 100,
      formatter(props: { row: DataRow }) {
        const { status } = props.row
        const draft = status === 'draft'
        return <$ChipContainer data-simplebar>
          <$Chip color={draft ? newTheme.colors.neutral.light : newTheme.colors.secondaries.light}>{draft ? 'Draft' : 'Submitted'}</$Chip>
        </$ChipContainer>
      },
    },
    { key: APPLICATION_FIELDS.PI_NAME, type: FILTER_TYPE.TEXT, name: 'PI Name', width: 150 },
    { key: APPLICATION_FIELDS.PI_AFFILIATION, type: FILTER_TYPE.TEXT, name: 'Affiliation', width: 130 },
    { key: APPLICATION_FIELDS.RESEARCH_FIELD_GROUP, type: FILTER_TYPE.TEXT, name: 'Research Field Group', width: 180 },
    { key: APPLICATION_FIELDS.RESEARCH_FIELD_TITLE, type: FILTER_TYPE.TEXT, name: 'Research Field Title', width: 165 },
    { key: APPLICATION_FIELDS.PARTITION, type: FILTER_TYPE.TEXT, name: 'Partition', width: 110 },
    { key: APPLICATION_FIELDS.SUBMITTED, type: FILTER_TYPE.TEXT, name: 'Submit Date', width: 115,
      formatter(props: { row: DataRow }) {
        const { submittedAt = '' } = props.row
        return <div data-simplebar>
          {new Date(submittedAt) > new Date('2000-01-01') ? 
            `${BrowserLocaleDateFormatter.format(new Date(submittedAt))} ${BrowserLocaleTimeFormatter.format(new Date(submittedAt))}`
            : ''}
        </div>
      },
    },
    //{ key: 'ownerId', type: FILTER_TYPE.TEXT, name: 'Lead Rapporteur' }, //TODO: Need BE
    //{ key: 'ownerId', type: FILTER_TYPE.TEXT, name: 'Second Rapporteur' }, //TODO
  ]

  const exportAll = async () => {
    try {
      const { data } = await searchApplications({ ...allFilters, limit: 99999, offset: 0 }).unwrap()
      return data as DataRow[]
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const call = dashboardState?.call
  const step = dashboardState?.step
  const name = dashboardState ? DASHBOARD_STATS_TEXTS[dashboardState?.name as DASHBOARD_STATS] : ''
  const subTitleText = dashboardState ? `${step.title} - ${name}` : ''

  return (
    <>
      <$Home>
        <Title noMargin={!!dashboardState} alternate fontSize={22}>{dashboardState ? call.title : 'Submissions'}</Title>
        {dashboardState ? 
          <$SubTitleContainer>
            <Title subTitle alternate fontSize={18}>{subTitleText}</Title> 
            <Button onClick={cleanDashboardFilter}>Clear Filter</Button>
          </$SubTitleContainer>
          : null}
        <ExportableTable
          title='Applications'
          initialSort={[{ columnKey: (order || APPLICATION_FIELDS.SUBMITTED) as APPLICATION_FIELDS, direction: orderDirection || 'DESC' }]}
          rows={applicationsData}
          columns={columns}
          total={total}
          page={pageQuery}
          initFilters={allFilters.searchBy}
          localFilter={false}
          rowsPerPage={limit}
          noFilterType
          onChangePage={handleChangePage}
          onSortColumn={onSortColumn}
          onFilters={onFilters}
          noMultipleSorting
          exportAll={exportAll}
        />
      </$Home>
    </>
  )
}
