import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { SvgIcon } from '../';
export const $Tooltip = styled((props) => (React.createElement(Tooltip, { title: React.createElement(Grid, { container: true, alignItems: 'flex-start' },
        !props.noIcon && React.createElement(SvgIcon, { name: 'info', size: 11, color: 'secondary' }),
        React.createElement("div", null, props.text)), classes: { popper: props.className }, ...props }))) `
  svg {
    margin-right: ${({ noIcon }) => noIcon ? 0 : 4}px;
  }
  & .MuiTooltip-tooltip {
    display: ${({ show }) => show ? 'inherit' : 'none'};
    background-color: #FFDCC4;
    max-width: unset;
    color: #2D3132;
    white-space: pre-wrap;
  }
  & .MuiTooltip-arrow {
    color: #FFDCC4;
  }
  `;
