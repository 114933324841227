import React, { FC, useRef, useState, ReactNode } from 'react'
import { Title, BrowserLocaleDateFormatter, Divider, NewButton, newTheme } from 'prace-common-components'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { $AppDetails, $Chip, $KeyParam } from './styles'
import { useGetCINESAppQuery, useLazyDownloadCinesFileQuery, useLazyDownloadProposalFilesQuery } from 'store/api/cines'

const scientificReviewAllKeyMapping = {
  id: 'ID',
  selfAssessment: 'Reviewer Self Assessment',
  levelConfidence: 'Confidence Level in Assessing the Proposal',
  explainConfidence: 'Confidence Level Explanation',
  proposalAssessment: 'Proposal Assessment',
  quality: 'Quality of the Proposal',
  qualityClassification: 'Quality Classification',
  impact: 'Impact of the Proposal',
  impactClassification: 'Impact Classification',
  team: 'Applicant/Team',
  teamClassification: 'Team Classification',
  management: 'Resources and Management',
  managementClassification: 'Resources and Management Classification',
  conclusion: 'Overall Conclusions',
  flawed: 'Scientifically or technically flawed',
  criteriaNotMet: 'Not able to meet one of more of the assessment criteria',
  hasClearWeaknesses: 'Meets all the assessment criteria but has clear weaknesses',
  hasMinorWeaknesses: 'Meets all the assessment criteria but with minor weaknesses',
  strongProposal: 'A strong proposal that broadly meets all assessment criteria',
  veryStrongProposal: 'A very strong proposal that fully meets all the assessment criteria',
  toBeAwardedFull: 'Project to be awarded in Full?',
  amountTimeNecessary: 'Amount of computing time considered necessary for the project',
  reasonForChange: 'Reasons for Change',
  score: 'Score',
  submitted: 'Submitted',
  submittedAt: 'Submission Date',
  conflictInterest: 'Conflict of Interest',
  amountVsResources: 'Amount & Resources',
  version: 'Version',
}

const technicalReviewAllKeyMapping = {
  completed: 'Completed',
  completedAt: 'Completed at',
  commentResources: 'Comment Resources',
  codeSuitability: 'Code Suitability',
  codeInstalled: 'Code Installed',
  timeVsWorkPlan: 'Time Vs Work Plan',
  furtherTesting: 'Further Testing',
  anticipatedEfficiency: 'Anticipated Efficiency',
  proposedHours: 'Proposed Hours',
  reasonsRejection: 'Reasons Rejection',
  adviceRejection: 'Advice Rejection',
  additionalComments: 'Additional Comments',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  codeSuitabilityYes: 'Code Suitability',
  codeInstalledYes: 'Code Installed',
  timeVsWorkPlanYes: 'Time Vs Work Plan',
  furtherTestingYes: 'Further Testing',
  moveToOtherSites: 'Move To Other Sites',
  moveToOtherSites_reasons: 'Move To Other Sites Reasons',
  reasonsCondAccepted: 'Reasons Cond Accepted',
  computingCenter: 'Computing Center',
  moveToAnotherMachine: 'Move To Another Machine',
  classification: 'Classification',
}

const proposalTypeKeyMapping = {
  proposal: 'Proposal',
  id: 'ID',
  alias: 'Alias',
  name: 'Name',
}

const proposalRestKeyMapping = {
  id: 'ID',
  discr: 'Description',
  counter: 'Suffix ID',
  number: 'Project ID',
  call_year: 'Year of the Call',
  call_number: 'Call Number',
  createdAt: 'Creation Date',
  updatedAt: 'Update Date',
  researchField: 'Research Field',
  researchFieldAggregate: 'Research Field Aggregate',
  researchFieldTree: 'Research Field Tree',
  allocation_period_id: 'Allocation Period ID',
  name: 'Project Title',
  submitted: 'Submitted',
  submittedAt: 'Submission Date',
  eligible: 'Eligible',
  resubmission: 'Resubmission',
  resubmission_of: 'Resubmission of the Proposal',
  continuation: 'Continuation',
  continuation_of: 'Continuation of the Proposal',
  continuation_ongoing: 'Continuation Ongoing',
  continuation_finished: 'Continuation Finished',
  notapplicable: 'Not Applicable',
  additionalRfKeywords: 'Additional Keywords',
  deleted: 'Deleted',
  deleted_at: 'Deleted Date',
  deleted_by: 'Deleted By',
  deleted_by_user: 'Deleted By User',
  summary: 'Summary',
  confidentiality: 'Confidentiality',
  confidentiality_reasons: 'Confidentiality Reasons',
  dissemination: 'Dissemination',
  support_other: 'Other Support',
  work_done_dev_code: 'Work Done Development Code',
  support_1: 'Support #1',
  support_2: 'Support #2',
  support_3: 'Support #3',
  multiyear_duration: 'Multiyear Duration',
  multiyear_reasons: 'Multiyear Reasons',
  civilian_project: 'Civilian Project',
  contact_name: 'Contact Name',
  contact_email: 'Contact Email',
  /* Preparatory Fields: */
  startDate: 'Start Date',
  preparatorySubtype: 'Preparatory Sub Type',
  scientificCase: 'Scientific Case',
  totalStorage: 'Total Storage',
  coreMemoryMax: 'Core Memory Max',
  nameAndVersion: 'Name and Version',
  license: 'License',
  mainAlgorithms: 'Main Algorithms',
  bottlenecks: 'Bottlenecks',
  bottlenecksSolutions: 'Bottleneck Solutions',
  ioExpectedBottleneck: 'IO Expected Bottleneck',
  implementation: 'Implementation',
  freqSizeDatInOut: 'Freq. Size Dat. In Out',
  fileSizesTypicalRun: 'File Sizes Typical Run',
  currentTargetPerformance: 'Current Target Performance',
  optimizationWork: 'Optimization Work',
  wishPraceToSolve: 'Wish PRACE To Solve',
  levelPraceCollaboration: 'Level PRACE Collaboration',
  impactOptimizationWork: 'Impact Optimization Work',
  confidentialityReasons: 'Confidentiality Reasons',
  userAgreementApproved: 'UserAgreement Approved',
  personMonthsEstimate: 'Person Months Estimate',
  optimizationPraceExpert: 'Optimization PRACE Expert',
  personMonthsPraceExpert: 'Person Months PRACE Expert',
  isTier1Machine: 'Is Tier 1 Machine?',
  tier1MachineName: 'Tier 1 Machine Name',
  tier1HardwareArchitecture: 'Tier 1 Hardware Architecture',
  multiMachineReasons: 'Multi Machine Reasons',
}

const preparatoryTechReviewKeyMapping = {
  computingCenter: 'Computing Center',
  complete: 'Complete',
  completedAt: 'Completed at',
  completedBy: 'Completed By',
  commentData: 'Comment Data',
  codeInstalled: 'Installed Code',
  codeInstalledYes: 'Code Installed?',
  targetsFeasible: 'Feasible Targets',
  targetsFeasibleYes: 'Targets Feasible?',
  furtherTesting: 'Testing Further',
  furtherTestingYes: 'Further Testing?',
  impactCodeOptimization: 'Impact Code Optimization',
  impactCodeOptimizationYes: 'Impact Code Optimization?',
  classification: 'Classification',
  reapplication: 'Reapplication',
  preparatorySubtype: 'Preparatory Sub type',
  reasonsRejection: 'Rejection Reasons',
  adviceRejection: 'Rejection Advice',
  additionalComments: 'Additional Comments',
  createdAt: 'Created At',
  createdBy: 'Created By',
  updatedAt: 'Updated At',
  updatedBy: 'Updated By',
  completedByUser: 'Completed By User',
  createdByUser: 'Created By User',
  updatedByUser: 'Updated By User',
}

const proposalResourcesKeyMapping = {
  central_memory: 'Central Memory',
  permanent_file_storage: 'Permanent File Storage',
  temporary_file_storage: 'Temporary File Storage',
  core_time_typical_run: 'Core Time Typical Run',
  core_hours_min: 'Core Hours Min',
  core_hours_avg: 'Core Hours Avg',
  core_hours_max: 'Core Hours Max',
  core_memory_min: 'Core Memory Min',
  core_memory_avg: 'Core Memory Avg',
  core_memory_max: 'Core Memory Max',
  io_intensive: 'IO Intensive',
  total_storage: 'Total Storage',
  max_number_of_files: 'Max Number Of Files',
  amount_data_transfered: 'Amount Data Transfered',
  strategy_data_handling: 'Strategy Data Handling',
  wallclock_time_typical_run_old: 'Wallclock Time Typical Run Old',
  number_of_simultaneous_jobs: 'Number Of Simultaneous Jobs',
  io_strategy: 'IO Strategy',
  total_storage_scratch: 'Total Storage Scratch',
  total_storage_work: 'Total Storage Work',
  total_storage_home: 'Total Storage Home',
  total_storage_archive: 'Total Storage Archive',
  total_storage_scratch_unit: 'Total Storage Scratch Unit',
  total_storage_work_unit: 'Total Storage Work Unit',
  total_storage_home_unit: 'Total Storage Home Unit',
  total_storage_archive_unit: 'Total Storage Archive Unit',
  max_number_of_files_scratch: 'Max Number Of Files Scratch',
  max_number_of_files_work: 'Max Number Of Files Work',
  max_number_of_files_home: 'Max Number Of Files Home',
  max_number_of_files_archive: 'Max Number Of Files Archive',
  amount_data_transfered_unit: 'Amount Data Transfered Unit',
  wallclock_time_typical_run: 'Wallclock Time Typical Run',
  wallclock_time_typical_run_unit: 'Wallclock Time Typical Run Unit',
  checkpoints_enabled_code: 'Checkpoints Enabled Code',
  checkpoints_max_interval: 'Checkpoints Max Interval',
  io_strategy_parallel: 'IO Strategy Parallel',
  io_data_traffic: 'IO Data Traffic',
  io_generated_files_per_hour_typical_job: 'IO Generated Files Per Hour Typical Job',
}

const callRestKeyMapping = {
  id: 'ID',
  year: 'Year of the Call',
  number: 'Call Number',
  counter: 'Suffix ID',
  callStartsOn: 'Call Starting Date',
  callEndsOn: 'Call Ending Date',
  name: 'Call Name',
  techReviewOpen: 'Technical Review Opened?',
  techReviewStartsAt: 'Technical Review Starting Date',
  techReviewEndsAt: 'Technical Review Ending Date',
  sciReviewOpen: 'Scientific Review Opened?',
  sciReviewStartsAt: 'Scientific Review Starting Date',
  sciReviewEndsAt: 'Scientific Review Ending Date',
  applicantResponseOpen: 'Applicant Response Opened?',
  applicantResponseStartsAt: 'Applicant Response Starting Date',
  applicantResponseEndsAt: 'Applicant Response Ending Date',
  allocationPeriodId: 'Allocation Period ID',
  researchFieldTree: 'Research Field Tree',
  link1: 'Link 1',
  link2: 'Link 2',
  linkGuidelineReviewer: 'Guideline Reviewer Link',
  linkTermsOfReference: 'Terms of Reference Link',
}

const yesNoKeys = [
  'flawed',
  'submitted',
  'conflictInterest',
  'criteriaNotMet',
  'hasClearWeaknesses',
  'hasMinorWeaknesses',
  'strongProposal',
  'veryStrongProposal',
  'toBeAwardedFull',
  'eligible',
  'resubmission',
  'resubmissionOf',
  'continuation',
  'continuationOngoing',
  'continuationFinished',
  'notapplicable',
  'deleted',
  'techReviewOpen',
  'sciReviewOpen',
  'applicantResponseOpen',
  'confidentiality',
  'civilian_project',
  'checkpoints_enabled_code',
  'codeSuitabilityYes',
  'codeInstalledYes',
  'complete',
  'completed',
  'furtherTestingYes',
  'timeVsWorkPlanYes',
  'moveToOtherSites',
  'targetsFeasibleYes',
  'impactCodeOptimizationYes',
  'reapplication',
  'userAgreementApproved',
  'isTier1Machine',
]

const classificationKeys = [
  'levelConfidence',
  'qualityClassification',
  'impactClassification',
  'teamClassification',
  'managementClassification',
]

const classificationMap = {
  E: 'Excellent',
  H: 'High',
  M: 'Medium',
  L: 'Low',
}

export const CinesApp: FC = () => {
  const { id = '' } = useParams<{ id: string }>()
  const [withInfo, setWithInfo] = useState<boolean>(false)
  const ref = useRef(null)
  const [downloadProposalFiles, { isFetching: downloading }] = useLazyDownloadProposalFilesQuery()
  const [downloadFile] = useLazyDownloadCinesFileQuery()
  const { data: cinesApp, isFetching, isLoading } = useGetCINESAppQuery(id, { skip: !id})

  if(!cinesApp || isFetching || isLoading) return null

  const downloadFiles = async (id?: string) => {
    try {
      await downloadProposalFiles(id || '').unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  const {
    proposalType = {},
    applicant,
    scientificReviewAll = [],
    responseScientificReview = {},
    technicalReviewAll = [],
    responseTechnicalReview = {},
    call = { info: '' },
    number,
    created_at,
    updated_at,
    submitted,
    submitted_at,
    proposalId,
    proposalResourcesAll,
    work_done_dev_code,
    regular = {},
    preparatory = {},
    preparatoryTechReview,
    ...proposalRest
  } = cinesApp

  const { info, machine, machinePreparatory, ...callRest } = call
  const realMachine = machine || machinePreparatory

  const isRegular = proposalRest?.discr == 'regular'

  const renderValue = (key: string, renderKey: string, keyValue: string): string | ReactNode => {
    const yesNo = yesNoKeys.includes(key)
    const srClassification = classificationKeys.includes(key) || renderKey.toLowerCase().includes('classification')
    const date = new Date(keyValue)
    const isDate = renderKey.toLowerCase().includes('date') && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.valueOf())
    const value = isDate ? BrowserLocaleDateFormatter.format(date) : keyValue
    const isObject = Object.prototype.toString.call(keyValue) === '[object Object]'
    const objectString = (keyValue as unknown as { name?: string })?.name || (keyValue as unknown as { label?: string })?.label || ''
    const isLink = renderKey.toLowerCase().includes('link')
    if(srClassification) return <$Chip color={newTheme.colors.primaries.medium}>
      {classificationMap[keyValue as keyof typeof classificationMap] ||
        (isObject ? objectString : keyValue)}
    </$Chip>
    if(isObject) return objectString
    if(isLink) return <a onClick={() => onClickLink(keyValue)} href='#'>
      {keyValue}
    </a>
    return yesNo ?
      <$Chip color={Number(keyValue) || (keyValue === 'Y') ? newTheme.colors.primaries.medium : newTheme.colors.secondaries.medium}>
        {Number(keyValue) || (keyValue === 'Y') ? 'Yes' : 'No'}
      </$Chip>
      : value
  }

  const onClickLink = async (link: Nullable<string>) => {
    if(link && link !== '#') {
      const aux = link.split('/')
      const fileNameExtension = aux[aux.length - 1]
      try {
        await downloadFile(fileNameExtension).unwrap()
      } catch (err) {
        console.log(err)
      }
    }
  }

  if(withInfo) {
    const callinfo = document.getElementById('callInfo')
    if(callinfo) {
      const links = callinfo.getElementsByTagName('a')
      for(const link of links) {
        if(link.href.includes(window.location.hostname)) { // This means that the link was from a FE file
          const href = link.getAttribute('href') || ''
          link.addEventListener('click', (e) => {
            e.preventDefault()
            onClickLink(href)
          })
          link.setAttribute('href', '#')
        }
      }
    }
  }

  if(info && !withInfo) {
    setTimeout(() => {
      setWithInfo(true)
    }, 1000)
  }

  return (
    <>
      <$AppDetails container>
        <Grid item xs={12}>
          <Title noMargin alternate fontSize={22}>Proposal</Title>
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Project ID:</$KeyParam></b> {number}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Created At:</$KeyParam></b> {BrowserLocaleDateFormatter.format(new Date(created_at))}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Updated At:</$KeyParam></b> {BrowserLocaleDateFormatter.format(new Date(updated_at))}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Submitted:</$KeyParam></b>
          <$Chip color={Number(submitted) ? newTheme.colors.primaries.medium : newTheme.colors.secondaries.medium}>
            {Number(submitted) ? 'Yes' : 'No'}
          </$Chip>
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Submitted At:</$KeyParam></b> {BrowserLocaleDateFormatter.format(new Date(submitted_at))}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>ID:</$KeyParam></b> {proposalId}
        </Grid>
        <Grid item xs={12}>
          <NewButton
            loading={downloading}
            onClick={() => downloadFiles(number || 0)}>
              Download All Proposal Files
          </NewButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Title alternate noMargin fontSize={22}>Applicant Info</Title>
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>Name:</$KeyParam></b> {isRegular ? regular?.contact_name : preparatory?.contact_name}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>E-mail:</$KeyParam></b> {isRegular ? regular?.contact_email : preparatory?.contact_email}
        </Grid>
        <Grid item xs={4}>
          <b><$KeyParam>User ID:</$KeyParam></b> {applicant?.id}
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <Title noMargin alternate fontSize={22}>Call Info</Title>
        </Grid>
        <Grid item xs={12}>
          <b><$KeyParam>Description:</$KeyParam></b> <div ref={ref} id='callInfo' dangerouslySetInnerHTML={{ __html: info || '' }}></div>
        </Grid>
        <Grid item xs={3}>
          <b><$KeyParam>Machine:</$KeyParam></b>
          {realMachine?.length ? realMachine?.map((m: {name: string}) =>
            <$Chip key={m.name} color={newTheme.colors.primaries.medium}>{m.name}</$Chip>,
          ) : '-'}
        </Grid>
        {Object.keys(callRest || {}).map((key) => {
          const renderKey = callRestKeyMapping[key as keyof typeof callRestKeyMapping] || key
          const keyValue = callRest[key] || '-'
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 6 : 3} key={key}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        <Grid item xs={12}>
          <Divider />
          <Title noMargin alternate fontSize={22}>Proposal Info</Title>
        </Grid>
        <Grid item xs={12}>
          <b><$KeyParam>Summary:</$KeyParam></b> <div dangerouslySetInnerHTML={{ __html: isRegular ? regular?.summary || '' : preparatory?.summary || '' }}></div>
        </Grid>
        {Object.keys(proposalType || {}).map((key) => {
          const renderKey = proposalTypeKeyMapping[key as keyof typeof proposalTypeKeyMapping] || key
          const keyValue = proposalType[key] || '-'
          if(key === 'id') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={3} key={key}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        {Object.keys(regular || {}).map((key, idx) => {
          const renderKey = proposalRestKeyMapping[key as keyof typeof proposalRestKeyMapping] || key
          const keyValue = regular[key] || '-'
          if(key === '?column?') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 12 : 3} key={idx}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        {Object.keys(preparatory || {}).map((key, idx) => {
          const renderKey = proposalRestKeyMapping[key as keyof typeof proposalRestKeyMapping] || key
          const keyValue = preparatory[key] || '-'
          if(key === '?column?') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 12 : 3} key={idx}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        {Object.keys(proposalRest || {}).map((key, idx) => {
          const renderKey = proposalRestKeyMapping[key as keyof typeof proposalRestKeyMapping] || key
          const keyValue = proposalRest[key] || '-'
          if(key === '?column?') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 12 : 3} key={idx}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        <Grid item xs={12}>
          <b><$KeyParam>Work Done | Development Code:</$KeyParam></b> <div dangerouslySetInnerHTML={{ __html: work_done_dev_code || '' }}></div>
        </Grid>
        {proposalResourcesAll ? <Grid item xs={12}>
          <Title noMargin alternate fontSize={22}>Proposal Resources</Title>
        </Grid> : null}
        {Object.keys(proposalResourcesAll || {}).map((key, idx) => {
          const renderKey = proposalResourcesKeyMapping[key as keyof typeof proposalResourcesKeyMapping] || key
          const keyValue = proposalResourcesAll[key] || '-'
          if(key === 'id' || key === 'version' || key === 'proposal_id') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 6 : 3} key={idx}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        <Grid item xs={12}></Grid>
        {preparatoryTechReview ? <Grid item xs={12}>
          <Divider />
          <Title noMargin alternate fontSize={22}>Tech Review</Title>
        </Grid> : null}
        {Object.keys(preparatoryTechReview || {}).map((key, idx) => {
          const renderKey = preparatoryTechReviewKeyMapping[key as keyof typeof preparatoryTechReviewKeyMapping] || key
          const keyValue = preparatoryTechReview[key] || '-'
          if(key === '?column?') return null
          const value = renderValue(key, renderKey, keyValue)
          return <Grid item xs={keyValue?.length > 30 ? 12 : 3} key={idx}>
            <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
          </Grid>})}
        {scientificReviewAll?.map((review: any, idx: number) => <>
          <Grid item xs={12} key={idx}>
            <Divider />
            <Title noMargin alternate fontSize={22}>Scientific Review #{idx+1}</Title>
          </Grid>
          <Grid item xs={6} key={'revName' + idx}>
            <b><$KeyParam>Name</$KeyParam></b> {`${review?.reviewer?.firstname || ''} ${review?.reviewer?.lastname || ''}`}
          </Grid>
          <Grid item xs={6} key={'revEmail' + idx}>
            <b><$KeyParam>Email</$KeyParam></b> {review?.reviewer?.email || ''}
          </Grid>
          {Object.keys(review || {}).map((key) => {
            const renderKey = scientificReviewAllKeyMapping[key as keyof typeof scientificReviewAllKeyMapping] || key
            const keyValue = review[key] || '-'
            const value = renderValue(key, renderKey, keyValue)
            if(!value || key === 'id') return null
            return <Grid item xs={12} key={key + idx}>
              <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
            </Grid>
          })}
        </>,
        )}
        {responseScientificReview?.response ? <Grid item xs={12}>
          <Divider />
          <Title noMargin alternate fontSize={22}>Scientific Review Response</Title>
        </Grid> : null}
        {responseScientificReview?.response ? <Grid item xs={12}>
          <b><$KeyParam>Response:</$KeyParam></b> <div dangerouslySetInnerHTML={{ __html: responseScientificReview?.response || '' }}></div>
        </Grid> : null}
        {technicalReviewAll?.map((review: any, idx: number) => <>
          <Grid item xs={12} key={idx}>
            <Divider />
            <Title noMargin alternate fontSize={22}>Technical Review #{idx+1}</Title>
          </Grid>
          {Object.keys(review || {}).map((key) => {
            const renderKey = technicalReviewAllKeyMapping[key as keyof typeof technicalReviewAllKeyMapping] || key
            const keyValue = review[key] || '-'
            const value = renderValue(key, renderKey, keyValue)
            if(!value || key === 'id') return null
            return <Grid item xs={12} key={key + idx}>
              <b><$KeyParam>{`${renderKey}:`}</$KeyParam></b> {value}
            </Grid>
          })}
        </>,
        )}
        {(responseTechnicalReview?.response || responseTechnicalReview?.computingCenter?.name) ? <Grid item xs={12}>
          <Divider />
          <Title noMargin alternate fontSize={22}>Technical Review Response</Title>
        </Grid> : null}
        {responseTechnicalReview?.response ? <Grid item xs={12}>
          <b><$KeyParam>Response:</$KeyParam></b> <div dangerouslySetInnerHTML={{ __html: responseTechnicalReview?.response || '' }}></div>
        </Grid> : null}
        {responseTechnicalReview?.computingCenter?.name ? <Grid item xs={12}>
          <b><$KeyParam>Computing Center:</$KeyParam></b> {responseTechnicalReview?.computingCenter?.name || ''}
        </Grid> : null}
      </$AppDetails>
    </>
  )
}
