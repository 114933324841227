import * as React from 'react'
import { BrowserLocaleDateFormatter, BrowserLocaleTimeFormatter, NewButton, NewDatePicker } from 'prace-common-components'
import { $Container, $ModalContainer, $Grid, $Label, $Text } from './styles'
import { DeadlineModalProps } from './types'
import Grid from '@material-ui/core/Grid'

export const DeadlineModal: React.FC<DeadlineModalProps> = ({ currentDeadline, handleDeadline, handleCancel }) => {
  const [deadline, setDeadline] = React.useState<string>()

  const onChangeDeadline = (_name: string, value: StringNumber) => setDeadline(value as string)

  const submitDeadline = () => {
    if(deadline) handleDeadline(deadline)
  }

  return (
    <$ModalContainer>
      <>
        <$Grid container wrap='nowrap'>
          <Grid item xs={6}>
            <$Label shrink>Current Deadline</$Label>
            <$Text>{currentDeadline ? `${BrowserLocaleDateFormatter.format(new Date(currentDeadline))} ${BrowserLocaleTimeFormatter.format(new Date(currentDeadline))}` : '-'}</$Text>
          </Grid>
          <Grid item xs={6}>
            <NewDatePicker withTime title='New Deadline' name='deadline' value={deadline} onChange={onChangeDeadline} />
          </Grid>
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton onClick={submitDeadline}>Change</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
